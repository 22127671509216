import React from 'react'

const PriceCard = (props) => {
  return (
    <div className="p-4 xl:w-1/4 md:w-1/2 w-full animate-grow">
        <div className="h-full p-6 rounded-lg border-2 border-primary flex flex-col relative overflow-hidden">
              <h2 className="text-sm tracking-widest title-font mb-1 font-bold text-primary">{props.title}</h2>
              <h1 className="text-center text-5xl text-secondary pb-4 mb-4 border-b border-gray-200 leading-none">{ props.price }</h1>
          <p className="flex items-center text-gray-600 mb-2">
            <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-red-100 text-primary rounded-full flex-shrink-0">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" className="w-3 h-3" viewBox="0 0 24 24">
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
                  </span>
                  {props.info[0]}
          </p>
          <p className="flex items-center text-gray-600 mb-2">
            <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-red-100 text-primary rounded-full flex-shrink-0">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" className="w-3 h-3" viewBox="0 0 24 24">
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
            </span>{props.info[1]}
          </p>
          <p className="flex items-center text-gray-600 mb-2">
            <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-red-100 text-primary rounded-full flex-shrink-0">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" className="w-3 h-3" viewBox="0 0 24 24">
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
            </span>{props.info[2]}
            </p>
            <p className="flex items-center text-gray-600 mb-6">
            <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-red-100 text-primary rounded-full flex-shrink-0">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" className="w-3 h-3" viewBox="0 0 24 24">
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
            </span>{props.info[3]}
            </p>
                      
          <a  href='#cours' className="flex justify-center mt-auto text-white  bg-primary border-0 py-2 px-4 w-full focus:outline-none hover:bg-secondary rounded uppercase">Rejoins  nous !
           
          </a>
          
        </div>
      </div>
  )
}

export default PriceCard