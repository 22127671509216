import React from 'react';
import PriceCard from './shared/PriceCard';
import Title from './shared/Title';

const Price = () => {
  return (
    <section id="price" className="container px-5 py-24 mx-auto">
      <div className="mb-20 ">
        <Title
          before=""
          black="Pr"
          title="ix"
          desc="Plusieurs prix pour plus de diversité"
        />
      </div>

      <div className="flex flex-wrap justify-center -m-4">
        <PriceCard
          title="Self"
          price="120€"
          info={[
            'Bonne ambiance assurée',
            'Sport à volonté',
            'Tous les mercredi 19h30',
            'Hammam disponible'
          ]}
        />
        <PriceCard
          title="Formule 2 cours ou plus"
          price="180€"
          info={[
            'Bonne ambiance assurée',
            'Karaté e/ou Self à volonté',
            'Tous les lundi 20h, jeudi 18h, mercredi 19h30 et les cours préparation aux grades',
            'Hammam disponible les mercredi'
          ]}
        />
        <PriceCard
          title="Karaté adulte"
          price="120€"
          info={[
            'Bonne ambiance assurée',
            'Karaté à volonté',
            'Tous les lundi 20h00',
            'Cours préparation aux grades'
          ]}
        />
        <PriceCard
          title="Karaté enfants"
          price="100-120 €"
          info={[
            'Tous les mercredi 18h15',
            'Cours de 45 min (100€)',
            'Cours de 1h (120€)'
          ]}
        />
      </div>
    </section>
  );
};

export default Price;
