import React from 'react'


const GallerieS = () => {

    const images = () => {
        let imageList = []

        for (let i = 1; i < 16; i++) {
            imageList[i - 1] = {id: i, img: require(`../assets/self/gallery/${i}.jpg`), alt:i}
        }

        return imageList
    }

  return (
   <section className="text-gray-600 body-font">
    <div className="container px-5 py-24 mx-auto flex flex-wrap">
        <p className="lg:pl-6 lg:w-2/3 mx-auto leading-relaxed text-2xl mb-20 text-primary font-bold text-center">Développer une boîte à outils de techniques réalistes et utiles</p>
          <div className="flex flex-wrap justify-center -m-4">
              {
                images().map((element, index) => {
                    return <div key={index} className="lg:w-1/3 sm:w-1/2 p-4">
                        <img className="w-full h-52 object-cover object-center" src={element.img} alt={element.alt} />
                    </div>
                })
              }
          </div>
    </div>
  </section>
  )
}

export default GallerieS