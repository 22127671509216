import React from 'react'

const Title = (props) => {
  
  return (
    <div className="">
         <p className="text-center text-3xl font-bold text-primary uppercase ">
        {props.before} <span className='text-secondary'>{props.black}</span>{props.title}
            </p>
            <p className="text-center mb-12 text-xl font-normal text-gray-500 ">
                {props.desc}
            </p>
    </div>
  )
}

export default Title