import React from 'react'


const CallToActions = () => {
  return (
    <aside
  className="overflow-hidden bg-sunburst bg-center bg-no-repeat bg-cover"
>
  <div className="container mx-auto p-8 md:p-12 lg:px-16 lg:py-24 ">
    <div className="flex flex-col justify-center text-center">
      <h2 className="text-primary text-2xl font-bold text-center sm:text-3xl md:text-5xl">
        Rejoins-nous
      </h2>

      <p
        className="text-secondary md:mt-6 md:text-lg md:leading-relaxed md:block"
      >
                Une bonne ambiance assurée !

      </p>

      
    </div>
  </div>
</aside>

  )
}

export default CallToActions