import React, { useState, useEffect } from 'react';

import logo from '../assets/logo-black.png';

const NavBar = () => {
  //let navigate = useNavigate();

  const [fixClass, setFix] = useState(false);

  const setFixed = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 500
        ? setFix('fixed z-50 bg-white w-full')
        : setFix('container');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', setFixed);

    return () => {
      window.removeEventListener('scroll', setFixed);
    };
  }, []);

  const handleLoginPage = async (e) => {
    // navigate("/login")
  };

  return (
    <header className="text-gray-600 body-font">
      <div
        className={`${fixClass} mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center shadow-sm`}
      >
        <a
          href="#hero"
          className="flex items-center mb-4 font-medium title-font text-secondary md:mb-0"
        >
          <img
            src={logo}
            className="object-cover rounded-full w-14"
            alt="Logo"
          />
          <span className="ml-3 text-xl text-black">
            EC<span className="text-red-600">KSD</span>
          </span>
        </a>
        <nav className="flex flex-wrap items-center justify-center space-x-4 text-base md:ml-auto md:mr-auto text-primary">
          <a className=" hover:text-secondary" href="#cours">
            Cours
          </a>
          <a className=" hover:text-secondary" href="#karate">
            Karaté
          </a>
          <a className=" hover:text-secondary" href="#self">
            Self-défense
          </a>
          <a className=" hover:text-secondary" href="#contact">
            Contact
          </a>
          <a className=" hover:text-secondary" href="#contact">
            Documents
          </a>
          <a className=" hover:text-secondary" href="#price">
            Prix
          </a>
        </nav>
        <button
          className="items-center hidden px-3 py-1 mt-4 text-base bg-gray-100 border-0 rounded md:inline-flex focus:outline-none hover:bg-gray-200 md:mt-0"
          onClick={handleLoginPage}
        >
          login
        </button>
      </div>
    </header>
  );
};

export default NavBar;
