import React from 'react'

import renaud from '../assets/self/renaud.jpg'
import moi from '../assets/self/moi.jpg'

const Ouvert = () => {
  return (
     <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-wrap justify-around -m-4">
              <div className="xl:w-1/2 md:w-1/2 p-4">
                    <div className=" p-6 rounded-lg">
                    <img className="h-52 rounded-lg w-full object-cover object-center mb-6" src={renaud} alt="renaud" />                    
                    <h2 className="text-lg text-primary font-medium title-font mb-4">Réflexes</h2>
                    <p className="leading-relaxed text-base"> Venez acquérir des réflexes de base pour prévenir les ennuis, les
          éviter, pour ainsi sortir sans problème d’une situation
          potentiellement dangereuse</p>
                    </div>
              </div>
              <div className="xl:w-1/2 md:w-1/2 p-4">
                    <div className=" p-6 rounded-lg">
                    <img className="h-52 rounded-lg w-full object-cover object-center mb-6" src={moi} alt="renaud" />                    
                    <h2 className="text-lg text-primary font-medium title-font mb-4">Ouvert à tous</h2>
                    <p className="leading-relaxed text-base"> Tout le monde peut venir Les conseils sont personnalisés en fonction de
        la situation de chacun</p>
                    </div>
                </div>
          </div>
   </div>
  )
}

export default Ouvert