import React from 'react'
import { ParallaxBanner } from 'react-scroll-parallax'

const Pub = (props) => {
  return (

    <ParallaxBanner
      layers={[{ image: props.img, speed: -15 }]}
      className="aspect-[3/1]"
    />    
      
  )
}

export default Pub