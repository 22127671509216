import React from 'react'

import ImgCardClick from './shared/ImgCardClick'

import wadoryu from '../assets/sponsors/wadoryu-academie.png'
import ecoleclermontoisekarate from '../assets/sponsors/ecoleclermontoisekarate.png'
import ecoleriomoisekarate from '../assets/sponsors/ecoleriomoisekarate.png'
import karatewadoclubdelyon from '../assets/sponsors/karatewadoclubdelyon.png'
import equinoxe from '../assets/sponsors/equinoxe.png'
import ca from '../assets/sponsors/ca.png'

const Sponsors = () => {

  return (
    <section className="bg-secondary ">
        <div className="container px-5 py-24 mx-auto">
           
            <div className="flex flex-wrap justify-center -m-4">
                  <ImgCardClick link="http://www.wadoryu-academie.fr" img={wadoryu} alt="Wado Ryu Academie" />
                  <ImgCardClick link="http://www.ecoleclermontoisekarate.com" img={ecoleclermontoisekarate} alt="Clermont Karaté" />
                  <ImgCardClick link="http://www.ecoleriomoisekarate.com" img={ecoleriomoisekarate} alt="Riom Karaté" />
                  <ImgCardClick link="http://www.karatewadoclubdelyon.fr" img={karatewadoclubdelyon} alt="Lyon Wado Club" />
                  <ImgCardClick link="http://www.coach.equinoxe.perso.sfr.fr" img={equinoxe} alt="Equinoxe" />
                  <ImgCardClick link="https://www.credit-agricole.fr/particulier/agence/centre-france.html" img={ca} alt="Credit agricole" />                  
            </div>
        </div>
    </section>
  )
}

export default Sponsors