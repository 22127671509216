import React from 'react'

const ImgCardClick = (props) => {
  return (
    <div className="animate-grow p-4 lg:w-1/4 md:w-1/2">
        <div className="h-full flex flex-col items-center text-center">
                          
            <a href={props.link} target="_blank" title="acc" rel="noreferrer">
                <img
                    src={props.img}
                    className="flex-shrink-0 rounded-lg w-full h-32 md:h-56 object-contain object-center mb-4"
                    alt={props.alt}
                />
            </a>
                          
        </div>
      </div>
  )
}

export default ImgCardClick