import React from 'react'

const Teamate = (props) => {
  return (
    <div className="p-4">
            <div className="text-center mb-4 opacity-90">
                <div  className="block relative">
                    <img alt="profil" src={props.img} className="mx-auto object-cover object-top rounded h-52 w-52 "/>
                </div>
            </div>
            <div className="text-center">
                <p className="text-2xl text-primary ">
                    {props.name}
                </p>
                <p className="text-xl text-secondary  font-light">
                      {props.title}
                </p>
                <p className="text-md text-gray-500  max-w-xs py-4 font-light">
                      {props.desc}
                </p>
            </div>
            
        </div>
  )
}

export default Teamate