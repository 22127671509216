import React from 'react'
import logo from '../assets/logo-black.png'

const Footer = () => {

   const date = new Date()
  const year = date.getUTCFullYear()
  
  return (
    <footer className="text-gray-600 body-font">
  <div className="container px-5 py-8 mx-auto flex items-center sm:flex-row flex-col">
        <a href='#hero' className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
          
          <img src={logo} className="w-14 object-cover rounded-full" alt='Logo'/>
      
          <span className="ml-3 text-xl text-black">EC<span className='text-red-600'>KSD</span></span>
    </a>
    <p className="text-sm text-gray-500 sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0 mt-4">© {year} ECKSD —
      <a href="https://sortidocorp.ovh" className="text-gray-600 ml-1" rel="noopener noreferrer" target="_blank">SortidoCorp</a>
    </p>
    <span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
      <a href="https://www.facebook.com/ecoleceyratoise.ecksd" className="text-gray-500">
        <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-5 h-5" viewBox="0 0 24 24">
          <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
        </svg>
      </a>
      <a href="https://www.instagram.com/ecksd_karate" className="ml-3 text-gray-500">
        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-5 h-5" viewBox="0 0 24 24">
          <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
          <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
        </svg>
      </a>
      
    </span>
  </div>
</footer>
  )
}

export default Footer