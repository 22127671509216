import React from 'react'
import poster from '../assets/video/sxelf.jpg'
import video from '../assets/video/sxelf.mp4'

const Video = () => {

   const style = {
        objectFit: "cover"
    };
  
    return (
    <div  id="self" className="overflow-hidden w-full max-h-screen md:max-h-[600px]">
      <video        
        autoPlay
        loop
        muted
        playsInLine
        className="min-w-full h-screen"
        poster={poster}
              width="auto" height="auto"
              style={style}
      >
        <source
          src={video}
          type="video/mp4"
        />
      </video>
     
    </div>
         
  )
}

export default Video