import React from 'react'

import logo from '../assets/logo-black.png'

const Hero = () => {
  
    return (
      <section id="hero" className='overflow-hidden bg-hero bg-center bg-no-repeat bg-cover'>
    
        <div class="container mx-auto flex px-5 py-24 items-center justify-center flex-col">
          <img class="lg:w-2/6 md:w-3/6 w-5/6 mb-10 object-cover object-center rounded-xl shadow-lg bg-white" alt="hero" src={logo} />
          <div class="text-center lg:w-2/3 w-full">
            <h1 class="title-font sm:text-4xl text-3xl mb-8 font-medium text-white">
              Ecole Ceyratoise de Karaté Self Défense</h1>
            
            <div class="flex justify-center ">
              <a href='#cours' class="inline-flex text-white bg-primary border-0 py-2 px-6 focus:outline-none hover:bg-secondary rounded text-lg">Rejoins-nous</a>
            </div>
          </div>
        </div>
  </section>
    
  )
}

export default Hero